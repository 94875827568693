import { Button } from '@chakra-ui/react'
import { ReactElement } from 'react'

interface GemsSubmitButtonProps {
  label: string
  onClick: () => void
}

const GemsSubmitButton = ({ label, onClick }: GemsSubmitButtonProps): ReactElement => {
  return (
    <Button
      fontSize="s"
      fontWeight="regular"
      fontFamily="Noto Mono"
      variant="outline"
      borderRadius="8"
      letterSpacing="0.1em"
      width="280px"
      background="linear-gradient(to right, #161616, #1E1D1D, #161616)"
      bgPos="0% 50%"
      bgSize="200%"
      transition="0.5s"
      _hover={{
        animation: 'gradient 2s ease infinite',
        animationFillMode: 'forwards',
        bgPos: '99%',
      }}
      onClick={onClick}
    >
      {label}
    </Button>
  )
}

export default GemsSubmitButton
