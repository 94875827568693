import { switchAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  switchAnatomy.keys,
)

const baseStyle = definePartsStyle({
  container: {
    // ...
  },
  thumb: {
    bg: 'gray.800',
    _checked: {
      bg: 'gray.600',
    },
  },
  track: {
    border: '1px solid',
    borderColor: 'gray.700',
    padding: '5px',
    bg: 'gray.750',
    _checked: {
      bg: 'gray.750',
    },
  },
})

const searchToggle = definePartsStyle({
  thumb: {
    bg: 'gray.550',
    _checked: {
      bg: 'green.50',
    },
  },
})

export const switchTheme = defineMultiStyleConfig({ baseStyle, variants: { searchToggle } })
