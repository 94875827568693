import { extendTheme, type ThemeConfig } from '@chakra-ui/react'
import { switchTheme } from './switch.theme'

// You choose initial value.
// App color mode is detached from system color mode changes.
const config: ThemeConfig = {
  initialColorMode: 'dark', // 'dark' | 'light' | 'system'
  useSystemColorMode: false,
}
// This is to override Chakra's default theme
// See docs: https://chakra-ui.com/docs/styled-system/theme
// palx used to generate colors based on figma: https://palx.jxnblk.com/
export const theme = extendTheme({
  config,
  colors: {
    gray: {
      50: '#f2f2f2',
      100: '#d9d9d9',
      150: '#CCCCCC',
      200: '#bfbfbf',
      300: '#a6a6a6',
      400: '#8c8c8c',
      500: '#737373',
      550: '#626262',
      600: '#595959',
      700: '#404040',
      725: '#383838',
      750: '#232323',
      800: '#161616',
      900: '#0d0d0d',
    },
    green: {
      50: '#438D57',
      100: '#2f6a42',
    },
  },
  global: {
    body: {
      userSelect: 'none',
      userDrag: 'none',
    },
  },
  components: {
    Input: {
      baseStyle: {
        field: {
          _placeholder: {
            color: 'gray.500',
          },
        },
      },
    },
    Switch: switchTheme,
  },
})

export default theme
