import { Card, Heading, Text } from '@chakra-ui/react'

export interface Props {
  heading?: string
  description?: string
  align?: 'left' | 'center' | 'right'
}

const GemsCard = ({heading, description, align = 'center'}: Props) => {
  return (
    <Card w="380px" h="350px" bg="gray.900" justifyContent="center" borderRadius="15px" border="1px solid rgba(39,41,43,0.7)" padding="0 3rem" margin="1rem">
      <Heading fontFamily="Plus Jakarta Sans" fontSize="24px" fontWeight="medium" color="gray.100" textAlign={align} paddingBottom="1rem">
        {heading}
      </Heading>
      <Text fontFamily="Plus Jakarta Sans" fontSize="16px" color="gray.500" textAlign={align}>
        {description}
      </Text>
    </Card>
  )
}

export default GemsCard
