import { useEffect } from 'react'
import {HStack, Heading, Stack, Text} from '@chakra-ui/react'
import { isMobile, isTablet } from 'react-device-detect'
import gemsLogo from '/256x256.png'
import GemsContainer from '../GemsContainer'
import GemsCard from '../GemsCard'
import GemsSubmitButton from '../GemsSubmitButton'
import './DownloadPage.css'

const DownloadPageCardItems = [
  {
    heading: 'Install',
    description: 'After installing Gems, press ⌘G to open the app',
  },
  {
    heading: 'Setup',
    description: 'Connect Gems with your favorite apps.',
  },
  {
    heading: 'Enjoy',
    description: 'Get ready-to-use answers from all your knowledge.',
  },
]

const downloadItems = [
  {
    label: 'Apple',
    version: 'beta/gems-beta.dmg'
  },
  {
    label: 'Windows',
    version: 'beta/gems-beta-setup.exe'
  }
]

const DownloadPage = ({version}: {version?: string}) => {

  useEffect(() => {
    if (version) {
      window.open(`https://gems-releases.s3.eu-central-1.amazonaws.com/${version}`)
    }
  }, [version])

  return (
    <GemsContainer>
      <Stack spacing={12} align="center">
        <a href="https://www.gems.so/" target="_blank">
          <img src={gemsLogo} className="logo gems" alt="Gems logo" />
        </a>
        {isMobile || isTablet ? (
            <>
              <Heading fontSize="25px" fontFamily="Plus Jakarta Sans">Apologies, Gems works only on desktop for now</Heading>
              <Text fontFamily="Plus Jakarta Sans" fontSize="14px" color="gray.500" textAlign="center">
                It seems like you're coming from a mobile device. Please try to download Gems again from a desktop device.
              </Text>
            </>
        ) : (
            <>
              <Heading fontFamily="Plus Jakarta Sans">Download Gems</Heading>
              <HStack spacing={6}>
                {downloadItems.map((item) => {
                  return (
                      <div key={item.label}>
                        <GemsSubmitButton label={item.label} onClick={() => {
                          window.open(`https://gems-releases.s3.eu-central-1.amazonaws.com/${item.version}`)
                        }}/>
                      </div>
                  )
                })}
              </HStack>
              <HStack>
                {DownloadPageCardItems.map((item, index) => (
                    <GemsCard key={index} heading={item.heading} description={item.description} />
                ))}
              </HStack>
            </>
        )}
      </Stack>
    </GemsContainer>
  )
}

export default DownloadPage
