/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import theme from './theme/theme'
import '@fontsource/noto-mono'
import '@fontsource/plus-jakarta-sans'
import './App.css'
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import DownloadPage from './components/DownloadPage'


function App() {

  return (
    <ChakraProvider cssVarsRoot="body" theme={theme}>
      <ColorModeScript initialColorMode={theme?.config?.initialColorMode} />
      <DownloadPage />
      </ChakraProvider>
  )
}

export default App
