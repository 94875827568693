import { Container } from '@chakra-ui/react'
import { ReactElement } from 'react'

export interface GemsContainerProps {
  children: ReactElement
}

const GemsContainer = ({ children }: GemsContainerProps): ReactElement => {
  return (
    <Container
      height="100%"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      bgColor="gray.900"
    >
      {children}
    </Container>
  )
}

export default GemsContainer
